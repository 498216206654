.showContent {
  display: block;
}

.hideContent {
  display: none;
}

.nav-link {
  padding: 0.5rem 0.4rem;
}

.dashboard-status {
  padding-left: 2.5rem;
  padding-bottom: 1rem;
}

@media (max-width: 992px) {
  .card-header {
    display: none;
  }

  .col-md-4 {
    padding: 0;
  }

  .dashboard-status {
    padding: 0;
  }
}
