// 
// pagination.scss
//


// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    color: #323a46;
    background-color: #fff;
  }
  .page-link:hover {
    z-index: 2;
    color: #323a46;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
    cursor: default;
  }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.15rem rgba(108, 117, 125, 0.25);
  }  
}