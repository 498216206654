.list {
  list-style-type: none;
  .time {
    text-align: center;
    width: 40%;
  }
}

.title {
  width: 20%;
  float: left;
}

.workHours {
  display: flex;
}
