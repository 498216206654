@import "src/styles/variables";

#menu-item .activeLink {
  color: $iris-blue;
}

.open {
  display: block;
}

.hide {
  display: none;
}

i {
  margin-right: 10px;
}

.arrow-rotate {
  transition: transform 0.15s;
  position: absolute;
  right: 30px;
  display: inline-block;
  font-family: "Material Design Icons";
  text-rendering: auto;
  line-height: 1.5rem;
  font-size: 1.1rem;
  transform: rotate(90deg);
  &:before {
    content: "\F142";
  }
}
