$white: #ffffff;
$black: black;
$dark: #283238;
$grey: #757575;
$very-light-grey: #cccccc;
$red: red;
$background: #f7f8fa;
$raven: #6c757d;
$licorice: #323a46;
$light-sea-green: #1abc9c;
$wild-watermelon: #f1556c;
$aubergine: #221f20;
$iris-blue: #00acc1;
$pattens-blue: #dee2e6;
$storm-grey: #6e768e;
