//
// menu.scss
//

// Metis Menu Overwrite

.metismenu {
  padding: 0;

  li {
    list-style: none;
  }
  ul {
    padding: 0;
    li {
      width: 100%;
    }
  }
}

.nav-second-level,
.nav-third-level {
  li {
    a {
      padding: 8px 20px;
      color: $menu-item;
      display: block;
      position: relative;
      transition: all 0.4s;
      font-size: 0.875rem;
      &:focus,
      &:hover {
        color: $menu-item-hover;
      }
    }
  }

  span {
    a {
      padding: 8px 20px;
      color: $menu-item;
      display: block;
      position: relative;
      transition: all 0.4s;
      font-size: 0.875rem;
      &:focus,
      &:hover {
        color: $menu-item-hover;
      }
    }
  }
}

.nav-second-level,
.nav-third-level {
  li.active {
    > a {
      color: $menu-item-active;
    }
  }

  span.active {
    > a {
      color: $menu-item-active;
    }
  }
}

// Wrapper
#wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

//Content Page
.content-page {
  // margin-left: $leftbar-width;
  overflow: hidden;
  padding: 0 15px 5px 15px;
  min-height: 80vh;
  margin-top: $topbar-height;
}

// Sidemenu
.left-side-menu {
  left: 0;
  width: $leftbar-width;
  background: $bg-leftbar;
  bottom: 0;
  // padding: 20px 0;
  position: fixed;
  transition: all 0.2s ease-out;
  top: $topbar-height;
  box-shadow: $shadow;
}

// Sidebar
#sidebar-menu {
  // overflow-x: hidden;
  overflow: visible;

  // width: $leftbar-width-collapsed;
  height: 100vh;
  &:hover {
    width: 250px;
  }
  > ul {
    > li {
      > a {
        color: $menu-item;
        display: block;
        padding: 13px 20px;
        position: relative;
        transition: all 0.4s;
        font-family: $font-family-secondary;
        font-size: 0.95rem;

        &:hover,
        &:focus,
        &:active {
          color: $menu-item-hover;
          text-decoration: none;
        }
        > span {
          vertical-align: middle;
        }

        i {
          display: inline-block;
          line-height: 1.0625rem;
          margin: 0 10px 0 3px;
          text-align: center;
          vertical-align: middle;
          width: 20px;
        }
        .drop-arrow {
          float: right;
          i {
            margin-right: 0;
          }
        }
      }
      > a.active {
        color: $menu-item-active;
      }

      > ul {
        padding-left: 40px;

        ul {
          padding-left: 20px;
        }
      }
    }
  }
  .menu-arrow {
    transition: transform 0.15s;
    position: absolute;
    right: 30px;
    display: inline-block;
    font-family: "Material Design Icons";
    text-rendering: auto;
    line-height: 1.5rem;
    font-size: 1.1rem;
    transform: translate(0, 0);
    &:before {
      content: "\F142";
    }
  }
  .badge {
    margin-top: 4px;
  }

  li.active {
    > a {
      > span.menu-arrow {
        transform: rotate(90deg);
      }
    }
  }

  .menu-title {
    padding: 10px 20px;
    letter-spacing: 0.05em;
    pointer-events: none;
    cursor: default;
    font-size: 0.6875rem;
    text-transform: uppercase;
    color: $menu-item;
    font-weight: $font-weight-semibold;
  }
}
.no-transition {
  .left-side-menu {
    transition: none !important;
  }
}
// Enlarge menu
.enlarged {
  .logo-box {
    width: $leftbar-width-collapsed !important;
  }

  .logo {
    span.logo-lg {
      display: none;
      font-size: 20px;
    }
    span.logo-sm {
      display: block;
    }
  }
  // Side menu
  .left-side-menu {
    position: fixed;
    padding-top: 0;
    width: $leftbar-width-collapsed !important;
    z-index: 200;

    .slimScrollDiv,
    .slimscroll-menu {
      overflow: inherit !important;
      height: auto !important;
    }
    .slimScrollBar {
      visibility: hidden;
    }

    // Sidebar Menu
    #sidebar-menu {
      .menu-title,
      .menu-arrow,
      .label,
      .badge,
      .collapse.in {
        display: none !important;
      }
      .nav.collapse {
        height: inherit !important;
      }

      > ul {
        > li {
          position: relative;
          white-space: nowrap;

          > a {
            padding: 15px 20px;
            min-height: 54px;
            transition: none;

            &:hover,
            &:active,
            &:focus {
              color: $menu-item-hover;
            }
            i {
              font-size: 1.125rem;
              margin-right: 20px;
            }

            span {
              display: none;
              padding-left: 25px;
            }
          }

          &:hover {
            > a {
              position: relative;
              width: calc(190px + #{$leftbar-width-collapsed});
              color: $menu-item-active;
              background-color: $gray-100;

              span {
                display: inline;
              }
            }

            a.open,
            a.active {
              :after {
                display: none;
              }
            }

            > ul {
              display: block;
              left: $leftbar-width-collapsed;
              position: absolute;
              width: 190px;
              height: auto !important;
              box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, 0.2);

              ul {
                box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, 0.2);
              }
              a {
                box-shadow: none;
                padding: 8px 20px;
                position: relative;
                width: 190px;
                z-index: 6;
                &:hover {
                  color: $menu-item-hover;
                }
              }
            }
          }
        }

        ul {
          padding: 0;
          z-index: 9999;
          background-color: $bg-leftbar;

          li {
            &:hover {
              > ul {
                display: block;
                left: 190px;
                margin-top: -36px;
                position: absolute;
                width: 190px;
              }
            }

            > a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }
          li.active {
            a {
              color: $menu-item-active;
            }
          }
        }
      }
    }
  }

  // Content Page
  .content-page {
    margin-left: $leftbar-width-collapsed !important;
  }

  //Footer
  // .footer {
  //     left: $leftbar-width-collapsed !important;
  // }

  //User box
  .user-box {
    display: none;
  }
}

// Body min-height set
// body.enlarged {
//   min-height: 1200px;
// }

@media (max-width: 768px) {
  body {
    overflow-x: hidden;
    padding-bottom: 80px;
  }
  .left-side-menu {
    position: fixed;
    padding-top: 0;
    width: $leftbar-width-collapsed !important;
    z-index: 200;
    .arrow-rotate {
      display: none !important;
    }

    .slimScrollDiv,
    .slimscroll-menu {
      overflow: inherit !important;
      height: auto !important;
    }
    .slimScrollBar {
      visibility: hidden;
    }

    // Sidebar Menu
    #sidebar-menu {
      .menu-title,
      .menu-arrow,
      .label,
      .badge,
      .collapse.in {
        display: none !important;
      }
      .nav.collapse {
        height: inherit !important;
      }

      > ul {
        > li {
          position: relative;
          white-space: nowrap;

          > a {
            padding: 15px 20px;
            min-height: 54px;
            transition: none;

            &:hover,
            &:active,
            &:focus {
              color: $menu-item-hover;
            }
            i {
              font-size: 1.125rem;
              margin-right: 20px;
            }

            span {
              display: none;
              padding-left: 25px;
            }
          }

          &:hover {
            > a {
              position: relative;
              width: calc(190px + #{$leftbar-width-collapsed});
              color: $menu-item-active;
              background-color: $gray-100;

              span {
                display: inline;
              }
            }

            a.open,
            a.active {
              :after {
                display: none;
              }
            }

            > ul {
              display: block;
              left: $leftbar-width-collapsed;
              position: absolute;
              width: 190px;
              height: auto !important;
              box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, 0.2);

              ul {
                box-shadow: 3px 5px 10px 0 rgba(154, 161, 171, 0.2);
              }
              a {
                box-shadow: none;
                padding: 8px 20px;
                position: relative;
                width: 190px;
                z-index: 6;
                &:hover {
                  color: $menu-item-hover;
                }
              }
            }
          }
        }

        ul {
          padding: 0;
          z-index: 9999;
          background-color: $bg-leftbar;

          li {
            &:hover {
              > ul {
                display: block;
                left: 190px;
                margin-top: -36px;
                position: absolute;
                width: 190px;
              }
            }

            > a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }
          li.active {
            a {
              color: $menu-item-active;
            }
          }
        }
      }
    }
  }
  .sidebar-enable {
    .left-side-menu {
      display: none;
    }
  }
  .content-page,
  .enlarged .content-page {
    margin-left: 0 !important;
    padding: 0 10px;
  }
  .pro-user-name {
    display: none;
  }
  .logo-box {
    display: none;
  }
}

/* =============
  Small Menu
============= */

.left-side-menu-sm {
  .logo-box {
    width: $leftbar-width-sm;
  }
  .left-side-menu {
    width: $leftbar-width-sm;
    text-align: center;
    #sidebar-menu {
      > ul {
        > li {
          > a {
            > i {
              display: block;
              font-size: 18px;
              line-height: 24px;
              width: 100%;
              margin: 0;
            }
          }
        }
        ul {
          padding-left: 0;
          a {
            padding: 10px 20px;
          }
        }
      }
    }
    .menu-arrow,
    .badge {
      display: none !important;
    }
    & + .content-page {
      margin-left: $leftbar-width-sm;
    }
    + .content-page .footer {
      left: $leftbar-width-sm;
    }

    .menu-title {
      background-color: $gray-100;
    }
  }
}

.enlarged.left-side-menu-sm {
  #wrapper {
    .left-side-menu {
      text-align: left;

      ul {
        li {
          a {
            i {
              display: inline-block;
              font-size: 18px;
              line-height: 17px;
              margin-left: 3px;
              margin-right: 15px;
              vertical-align: middle;
              width: 20px;
            }
          }
        }
      }
    }
  }
}

// Leftbar-dark
.left-side-menu-dark {
  .navbar-custom {
    background-color: $blue;
  }

  .left-side-menu {
    background-color: $bg-leftbar-dark;
    box-shadow: none;
    border-right: 2px solid lighten($bg-leftbar-dark, 2%);

    #sidebar-menu {
      > ul {
        > li {
          > a {
            color: $menu-item-color-dark;

            &:hover,
            &:focus,
            &:active {
              color: $menu-item-hover-color-dark;
            }
          }
          > a.active {
            color: $menu-item-active-color-dark;
            background-color: lighten($bg-leftbar-dark, 5%);
            border-right-color: $menu-item-active-color-dark;
          }
        }
      }

      .menu-title {
        color: $gray-500;
      }
    }

    .nav-second-level,
    .nav-third-level {
      li {
        a {
          color: $menu-item-color-dark;
          &:focus,
          &:hover {
            background-color: transparent;
            color: $menu-item-hover-color-dark;
          }
        }
      }
    }

    .nav-second-level,
    .nav-third-level {
      li.active {
        > a {
          color: $menu-item-active-color-dark;
        }
      }
    }
  }
}

.enlarged.left-side-menu-dark #wrapper {
  .left-side-menu {
    #sidebar-menu {
      > ul {
        > li {
          &:hover > a {
            background-color: lighten($bg-leftbar-dark, 5%);
          }
        }
      }
    }
  }
}

// Leftbar with user
.user-pro-dropdown {
  background-color: $gray-100;
  box-shadow: none;
  padding: 15px 5px;
  width: 90%;
  margin-left: 5%;
  margin-top: 10px;

  .dropdown-item {
    border-radius: 3px;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
}
