@import "src/styles/variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
  text-decoration: none;
}
body {
  font-family: Cairo;
  background-color: $background;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.srv-validation-message {
  color: $red;
}

a {
  color: $storm-grey;
}

#link .active {
  color: $iris-blue;
}

td,
th {
  text-align: center !important;
}

.table thead th {
  vertical-align: top !important;
}

.mL {
  margin-left: 250px;
}
