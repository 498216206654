* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
  text-decoration: none; }

body {
  font-family: Cairo;
  background-color: #f7f8fa;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5; }

.srv-validation-message {
  color: red; }

a {
  color: #6e768e; }

#link .active {
  color: #00acc1; }

td,
th {
  text-align: center !important; }

.table thead th {
  vertical-align: top !important; }

.mL {
  margin-left: 250px; }
