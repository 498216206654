@import "src/styles/variables";

.workingHours {
  width: 100%;
  height: auto;
  ul {
    list-style-type: none;
    padding: 0;
  }

  input {
    font-size: 18px;
    font-weight: normal;
    color: $raven;
    width: 100%;
    border: none;
    background-color: transparent;
  }
  input[type="time"] {
    text-align: center;
    width: 40%;
    outline: none;
  }
}

.notFound {
  width: 100%;
  display: flex;
  justify-content: center;
}
