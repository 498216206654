.root {
  display: flex;
  flex-wrap: wrap;
  width: 30%;
  .formControl {
    margin: 8px;
    min-width: 120px;
    width: 100%;
    .selectEmpty {
      margin-top: 16px;
    }
    .label {
      transform: translate(14px, -15px) scale(0.75);
    }
  }
}
