.month-picker {
  .react-datepicker-year-header {
    display: none;
  }
  .react-datepicker {
    &__navigation {
      display: none;
    }
  }
}
