.show {
  cursor: pointer;
}

.mod-image {
  width: 50px;
  height: 50px;
}

.modifier-img {
  cursor: pointer;
}
