@media (max-width: 768px) {
  .tableFeedback {
    display: block;
    overflow-x: auto;
  }

  .paddingNone {
    padding: 0;
  }
}
