@import "src/styles/variables";

.productImage img {
  height: 30vh;
  width: 100%;
  object-fit: cover;
}

.product-action .btn-background {
  background-color: $aubergine;
  border-color: $aubergine;
  color: $white;
}
