@import "src/styles/variables";

.productDetails input[type="text"]:disabled {
  opacity: 0.5;
}

#wrapper {
  .Toastify__toast--success {
    width: 250px;
    background-color: $light-sea-green;
    border-color: $light-sea-green;
    color: $white;
    font-family: Nunito, sans-serif;
  }

  .Toastify__toast--error {
    width: 250px;
    background-color: $wild-watermelon;
    color: $white;
    border-color: $wild-watermelon;
    font-family: Nunito, sans-serif;
  }
}

.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
