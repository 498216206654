@import "src/styles/variables";

.toggle {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 2px;
  width: 60px;
  height: 30px;
  border-radius: 20px;
  background-color: $light-sea-green;
  transition: 0.5s;
  user-select: none;
  &-small {
    width: 36px;
    height: 20px;
  }
  cursor: pointer;
  &-block {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 2px solid $pattens-blue;
  }
  &-red {
    transition: 0.5s;
    background-color: $pattens-blue;
  }
  &-button {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: $white;
    transition: 0.5s;
    &-small {
      width: 18px;
      height: 18px;
    }
  }
  .vip {
    margin-left: 30px;
    transition: 0.5s;
    &-small {
      margin-left: 15px;
    }
  }
}
