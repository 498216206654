.reports {
  input.datepicker {
    height: 31px;
  }
  .react-datepicker-year-header {
    display: none;
  }
  input {
    height: 32px;
  }
}
