.duration,
.pointsRiyal {
  display: flex;
  align-items: center;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    margin-right: 5px;
  }
}
