@import "src/styles/variables";

.workingHours {
  width: 100%;
  height: auto;
  table {
    padding-left: 20px;
    border-left: 2px solid $black;
    opacity: 0.54;
  }
}

.deliveryTime {
  display: flex;
  justify-content: space-between;
  width: 220px;
}

.deliveryPhone {
  display: flex;
  justify-content: space-between;
  width: 120px;
}

.branchName {
  color: $raven;
}

.map {
  margin-bottom: 270px;
}
