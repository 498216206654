@import "src/styles/variables";

.hoverImage {
  position: relative;
}

.hoverImage::before {
  content: "";
  background-color: rgba($black, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  border-radius: 6px;
}

.hoverImage > img {
  position: absolute;
  z-index: 10;
  padding: 5px 0;
}

.opacityZero {
  position: absolute;
  opacity: 0;
}

.dropify {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed rgba($licorice, 0.3);
  border-radius: 6px;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 6px;
    z-index: 1;
    padding: 5px 0;
  }
  input {
    display: block !important;
    position: absolute;
    right: 0;
    left: 0;
    height: 70%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1000;
  }
  &-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    i {
      font-size: 50px;
      color: $very-light-grey;
    }
    h5 {
      color: $very-light-grey;
      margin: 0;
    }
  }
  &-replace {
    z-index: 100;
    color: $white;
  }
}
