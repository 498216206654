// //
// // general.scss
// //

// html {
//   position: relative;
//   min-height: 100%;
// }

// body {
//   // padding-bottom: 60px;
//   overflow-x: hidden;
// }

.btn-primary {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
  //   box-shadow: 0 0 0 2px rgba(#221f20, 0.5);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus {
  color: #fff;
  background-color: #111820;
  border-color: #111820;
}
.btn-primary:focus,
.btn-primary.active {
  box-shadow: 0 0 0 0.15rem rgba(108, 117, 125, 0.5);
}
